@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* 
{
    margin: 0;
  }
  
  body {
    /* First height property is for older browser in case if the browser doesn't support CSS variables.  */
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    margin: 0;
    font-family: 'Montserrat', sans-serif, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-style: normal;
  }

  p {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-style: normal;
}

  button {
    font-family: 'Montserrat'
  }

  input {
    font-family: 'Montserrat';
    border: none;
  }